import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || `Hello `}&#128075;{' my name is '}
            <span className="text-name text-color-main">Kushal</span>
            <br />
            <span className="hero-title--small">{subtitle || "Welcome to my website!"}</span>
          </h1>
          {/* <h3 className="text-name">I love programming cool apps and making our internet experience better and safer!</h3> */}
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">

          <p className="hero-cta">
            <Link to="about" smooth duration={1000}>
              {/* <span className="cta-btn cta-btn--hero"> What's more <span style={customStyle}>&#128515;</span>! */}
              <span className="cta-btn cta-btn--hero"> Check it out ...
                {/* {cta || 'Know more'} */}
              </span>
            </Link>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
